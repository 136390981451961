<template>
    <div class="editorWeb">
        <div class="clearFix search">
            <div class="floatLeft">
                <el-button class="themeButton" @click="showAddDialog">添加</el-button>
            </div>
            <div class="floatRight"> 
                <el-input
                    placeholder="请输入虚拟实验关键字"
                    v-model="searchVal"
                    clearable
                    @clear="clearSearch"
                    class="floatLeft expInput">
                </el-input>
                <el-button class="floatRight themeButton" @click="searchReportWeb">搜索</el-button>
            </div>
        </div>
            <el-card>
            <div class="select">
                    <el-table
                    :data="tableData"
                    stripe
                    style="width: 100% height:auto">
                    <el-table-column
                    type="index"
                    :index="indexMethod"
                    label="序号"
                    width="100">
                    </el-table-column>
                    <el-table-column
                        prop="school_name"
                        label="学校"
                        width="200">
                    </el-table-column>
                    <el-table-column
                        prop="fuzeren"
                        label="负责人"
                        width="160">
                    </el-table-column>
                    <el-table-column
                        label="虚拟实验名称ID"
                        width="160">
                        <template slot-scope="scope">
                            <div class="expId" @click="editExp(scope.row.exp_id)">{{scope.row.exp_id}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="exp_name"
                        label="虚拟实验名称">
                    </el-table-column>
                    <el-table-column
                        label="操作">
                            <template slot-scope="scope">
                                <div class="operatorItem floatLeft" @click="handleEditWeb(scope.row)">编辑</div>
                                <div class="operatorItem floatLeft" @click="handleDeleteWeb(scope.row.id)">删除</div>
                                <div class="operatorItem floatLeft" @click="previewWeb(scope.row.url)">预览</div>
                                <div class="operatorItem floatLeft" v-if="!scope.row.detail_id" @click="upLevel2(scope.row.id)">升级第二版</div>
                            </template>
                    </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="total"
                            :page-size="pageSize"
                            :current-page="currentPage"
                            @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
            </div>
        </el-card>
        <el-dialog
            title="添加"
            :visible.sync="AddVisible"
            width="30%">
            <el-form  :model="form" label-width="100px">
                                <el-form-item label="虚拟实验名称">
                                <el-input v-model="form.expName" placeholder="请输入虚拟实验名称"></el-input>
                                </el-form-item>
                                <el-form-item label="虚拟实验ID" >
                                <el-input v-model="form.expID" placeholder="请输入虚拟实验ID"></el-input>
                                    <!-- <el-select  
                                        filterable
                                        remote
                                        clearable
                                        v-model="form.expID" 
                                        placeholder="请搜索选择虚拟实验"
                                        :remote-method="getExpList" 
                                        >
                                    <el-option
                                        v-for="item in expList"
                                        :label="item.name+'-'+item.id"
                                        :value="item.id"
                                    ></el-option>
                                    </el-select> -->
                                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="themecancelButton" @click="AddVisible = false">取 消</el-button>
                <el-button class="themeButton" @click="AddVirtual">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" >
.editorWeb .el-card__body {
    padding: 0px !important; 
}
.editorWeb .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0D5570;
    color: #FFF;
}
     @import "@/assets/css/manager/editorWeb/editorWeb.scss";
</style>
<script>
    import editorWeb from "@/assets/js/manager/editorWeb/editorWeb.js";
    export default {
        ...editorWeb
    };
</script>