import API from '@/api/manager/editorWeb/editorWeb';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "editorWeb",
    inject:['reload'],
    components: {},
    data() {
        return {
          school_id: "",
          game_id: "",
          game_type_id: "",
          // 学校列表
          schoolList: [],
          gameList: [],
          gameTypeList: [],
          tapName: "exportGame",
          // 左侧功能项位置
          tabPosition: "left",
          tableData: [],
          AddVisible: false,
          currentPage: 1,
          pageSize: 10,
          total: 0,
          searchVal: "",
          form: {
            expName: "",
            expID: "",
          },
          // 通知内容
          noticeContent: "",
          loginTableData: [],
          searchValue:'',
          // 上传url
          uploadUrl: process.env.VUE_APP_DOMAIN_URL + "/backend/public/index.php/backStatistics/uploadExportCourseFile",
          // 上传excel 文件路径
          groupFilePath:'',
          fileList:[],
          expList:[]

        };
    },
    //页面创建
    created() {
        let self = this;
        self.getData(); 
    },

    methods: {
        previewWeb(url)
        {
          window.open(url)
        },
        getExpList(e) {
            try {
                let self = this;
                // 请求参数
                let data = {
                    search: e,
                    // lab_id:self.lab_id,
                    // school_id:self.school_id,
                    // page:self.currentPage,
                    // pagesize:self.pageSize
                }
                // 获取虚拟实验请求
                API.getExpList(data)
                    .then((result) => {
                        if (result.code > 0) {
                            self.expList = result.logList
                        }
                        else
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })
            }
            catch (error) {
                // Message.error('系统错误') 
            }
        },
        downloadImportTemplate()
        {
          var url = process.env.VUE_APP_DOMAIN_URL + "/backend/public/uploads/template/批量解锁虚拟实验模板.xlsx";
          window.open(url);
        },
        importCourseExcel()
        {
          let self = this;
          let data = {
            filePath: self.groupFilePath,
          };
          // 请求数据
          API.createCourseAndProjectAndGroup(data)
            .then((result) => {
              // 关闭loading
              nprogressClose();
              self.$Loading.hide();
              // Message.success(result.data.msg);
              self.$Tips({
                // 消息提示内容
                message:result.data.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'success',
                // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                displayTime:1500
              })
            })
        },
        /**
         * @name: 上传前校验
         * @author: camellia
         * @date: 2021-03-05
         */
        markHandleBeforeUpload(file) 
        {
          const self = this;
          var array = file.name.split(".");
          if (array.length < 2) 
          {
            // Message.error("文件错误！");
            self.fileList = [];
            self.$Tips({
              // 消息提示内容
              message:'文件错误',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button:[
                  {
                      type:1,
                      text:'知道了'
                  }
              ]
            })
            return;
          }
          var ext = array[array.length - 1];
          if (ext != "xls" && ext != "xlsx") 
          {
            // Message.error("文件格式为xls，xlsx，请重试！");
            self.fileList = [];
            self.$Tips({
              // 消息提示内容
              message:'文件格式为xls，xlsx，请重试',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button:[
                  {
                      type:1,
                      text:'知道了'
                  }
              ]
            })
            return;
          }
          const isLt2M = file.size / 1024 / 1024 < 5;
          if (!isLt2M) 
          {
            // Message.error("上传头像图片大小不能超过 5MB!");
            self.fileList = [];
            self.$Tips({
              // 消息提示内容
              message:'上传头像图片大小不能超过 5MB',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button:[
                  {
                      type:1,
                      text:'知道了'
                  }
              ]
            })
            return;
          }
        },
        handleExceed(files, fileList) 
        {
          // Message.warning(
          //   `当前限制选择 1 个文件，本次选择了 ${
          //     files.length
          //   } 个文件，共选择了 ${files.length + fileList.length} 个文件`
          // );
          this.$Tips({
            // 消息提示内容
            message:`当前限制选择 1 个文件，本次选择了 ${
                  files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType:'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button:[
                {
                    type:1,
                    text:'知道了'
                }
            ],
        })
        },
        beforeRemove(file, fileList) {
          return this.$confirm(`确定移除 ${file.name}？`);
        },
        handleRemove(file, fileList) {},
        /**
         * @name: 上传成绩excel成功
         * @author: camellia
         * @date: 2021-03-01
         * @param:	res	json	上传图片返回信息
         */
        markHandleAvatarSuccess(res) 
        {
          const self = this;
          if (res.code > 0) 
          {
            self.groupFilePath = res.path;
            // console.log(self.groupFilePath);
            // Message.success(res.msg);
            this.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'success',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              displayTime:1500
          })
          } 
          else 
          {
            // Message.error(res.msg);
            this.$Tips({
              // 消息提示内容
              message:res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType:'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              displayTime:1500
          })
          }
        },
        login(id)
        {
            let self = this;
            let data = {
              id: id,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            API.loginManager(data)
              .then((res) => {
                if (res.code > 0) 
                {
                  this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                    displayTime:1500
                  })
                self.reload();
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              });
        },

        searchTeacherList()
        {
            let self = this;
            if(self.searchValue == '')
            {
                // Message.warning("请填写教师信息！");
                this.$Tips({
                  // 消息提示内容
                  message:'请填写教师信息',
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
                  displayTime:1500
              })
                return;
            }
            let data = {
              searchValue: self.searchValue,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            API.getTeacherList(data)
              .then((res) => {
                if (res.code > 0) 
                {
                  self.loginTableData = res.list;
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              });
        },
        sendNotice()
        {
            let self = this;
            let data = {
              noticeValue: self.noticeContent
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            self.axios
              .post("/index.php/backStatistics/sendNotice", data)
              .then((res) => {
                if (res.data.code > 0) 
                {
                  // Message.success("操作成功");
                  this.$Tips({
                    // 消息提示内容
                    message:res.data.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              });
        },
        getData()
        {
            let self = this;
            self.getReportWebList();
        },
        GetSchool(school_name)
        {
            let self = this;
            self.axios
              .get("/index.php/backother/getSchoolList?school_name=" + school_name)
              .then((result=>{
                  self.schoolList = result.data.data;
              }))
        },
        GetGame()
        {
            let self = this;
            self.axios
              .get("/index.php/backStatistics/getGameList")
              .then((result) => {
                self.gameList = result.data.data;
              });
        },
        GetGameType()
        {
            let self = this;
            if(self.game_id)
            {
                self.axios
                  .get(
                    "/index.php/backStatistics/getGameTypeList?     game_id=" +
                      self.game_id
                  )
                  .then((result) => {
                    self.gameTypeList = result.data.data;
                  });
            }
            else
            {
                // Message.warning('请先选择小游戏');
                this.$Tips({
                  // 消息提示内容
                  message:"请先选择小游戏",
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  button:[
                      {
                          type:1,
                          text:'知道了'
                      }
                  ]
              })
            }
        },
        /**
         * @name: 导出小游戏数据
         * @author: LANXINYU
         * @date: 20211103
         */
        exportMiniGameData()
        {
            var self = this;
            if (self.game_type_id == "" || self.game_id == '' || self.school_id == '') 
            {
                // Message.warning("参数不能为空！");
                this.$Tips({
                  // 消息提示内容
                  message:"参数不能为空",
                  // 消息提示类型（success-成功,warning-警告/失败）
                  messageType:'warning',
                  button:[
                      {
                          type:1,
                          text:'知道了'
                      }
                  ]
              })
                return;
            }
            var url = process.env.VUE_APP_DOMAIN_URL + 
              "/backend/public/index.php/backStatistics/exportMiniGameData?school_id=" +
              self.school_id +
              "&game_id=" +
              self.game_id +
              "&game_type_id=" +
              self.game_type_id;

            window.open(url);
        },
        editExp(id)
        {
          window.open(`#/manager/expDetail?id=${id}`)
        },
        handleTapClick(tap)
        {
            let self = this;
            self.$router.push("/manager/editorWeb?currentPage="+self.currentPage+"&searchVal="+self.searchVal+"&tapName="+tap.name);
            if(self.tapName=='editWeb')
            {
                self.getReportWebList();
            }
            else
            {
                self.GetGame();
            }
        },
        getReportWebList()
        {
            let self = this;
            self.currentPage = self.$route.query.currentPage?self.$route.query.currentPage-0:1;
            self.searchVal = self.$route.query.searchVal?self.$route.query.searchVal:'';
            let data = {
              currentPage:self.currentPage,
              searchVal:self.searchVal
            }
            API.getReportWebList(data)
              .then((res)=>{
                self.tableData = res.data;
                if(!self.searchVal)
                {
                    self.total = res.total;
                }
                else
                {
                    self.total = res.data.length;
                }
            })
        },
        upLevel2(id)
        {
            let self = this;
            self.axios
                .post("/index.php/adminVirtualSimulation/upLevel2?id="+id)
                .then((res)=>{
                  self.getReportWebList();
                })
        },
        handleEditWeb(row)
        {
            let self = this;
            // self.$router.push('/manager/editWebDetail/'+row.id+'/'+row.detail_id)
            self.$router.push('/manager/editWebDetail?id='+row.id+'&detail_id='+row.detail_id)
        },
        /**
         * @name: 虚拟实验分类表格分页序号递增
         * @author: camellia
         * @date: 2021-03-05
         */
        indexMethod(index) 
        {
            let currentPage = this.currentPage - 0; // 当前页码
            let pageSize = this.pageSize - 0; // 每页条数
            return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
        },
        /**
        * @name: 展示添加对话框
        * @author: LANXINYU
        * @date: 20211119
        */
        showAddDialog()
        {
            let self =this;
            self.AddVisible=true;
        },
        AddVirtual()
        {
            let self = this;
            let data = {
              exp_name:self.form.expName,
              exp_id:self.form.expID
            }
            API.addReportWeb(data)
              .then((res)=>{
                if(res.code>0)
                {
                    self.AddVisible = false;
                    self.form.expName = '';
                    self.form.expID = '';
                    self.getReportWebList();
                    // Message.success('添加成功')
                    this.$Tips({
                      // 消息提示内容
                      message:'添加成功',
                      // 消息提示类型（success-成功,warning-警告/失败）
                      messageType:'success',
                      displayTime:1500
                  })
                }
                else
                {
                  this.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
                }
            })
        },
        handleCurrentChange(val)
        {
            let self = this;
            self.currentPage=val;
            self.$router.push("/manager/editorWeb?currentPage="+self.currentPage);
            self.getReportWebList();
        },
        searchReportWeb()
        {
            let self = this;
            self.currentPage = 1;
            self.$router.push("/manager/editorWeb?currentPage="+self.currentPage+"&searchVal="+self.searchVal);
            self.getReportWebList();
        },
        clearSearch()
        {
            let self =this;
            self.$router.push("/manager/editorWeb?currentPage=1&searchVal="+self.searchVal);
            self.getReportWebList();
        },
        handleDeleteWeb(id)
        {
            let self = this;
            let data = {
              id:id
            }
            self.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                API.deleteReportWeb(data)
                    .then((res)=>{
                      if(res.code>0)
                      {
                          self.getReportWebList();
                          // Message.success('删除成功')
                          this.$Tips({
                            // 消息提示内容
                            message:'删除成功',
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                      }
                  })
                });
        }
    }
};